import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
    await OneSignal.init({
        appId: 'c6d4523b-a66f-48c7-9ef3-301c206096bd',
        allowLocalhostAsSecureOrigin: true,
        safari_web_id: "web.onesignal.auto.2358eea7-3e97-4fb7-b492-b25c712616ac",
        notifyButton: {
            enable: true,
        },
    });
    OneSignal.Slidedown.promptPush();
}